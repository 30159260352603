










import { Component, Prop, Vue } from 'vue-property-decorator';
import TheCheckedMarkIcon from '@/components/icons/TheCheckedMarkIcon.vue';

@Component({
  components: { TheCheckedMarkIcon },
})
export default class Checkbox extends Vue {
  @Prop() variant!: any;
  @Prop() name!: string;

  onChange() {
    this.$emit('change-value', this.variant);
  }
}
