
























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import { UserSettingsToPickResult } from '@/interfaces/IUserSettingsToPick';
import IVariant from '@/interfaces/IVariant';

import getUserNotificationSettings from '@/graphql/me/account/UserSettingsToPick.query.gql';
import destinationsForNotificationsQuery from '@/graphql/destination/DestinationsForNotifications.query.gql';
import updateUserNotificationSettings from '@/graphql/me/account/UpdateUserNotificationSettings.mutation.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import Checkbox from '@/components/partials/elements/Checkbox.vue';
import TheCheckedMarkIcon from '@/components/icons/TheCheckedMarkIcon.vue';

@Component({
  components: {
    BackHeader,
    Checkbox,
    TheCheckedMarkIcon,
  },
  metaInfo: {
    title: 'Notification preferences',
  },
  apollo: {
    UserSettingsToPick: {
      query: getUserNotificationSettings,
      fetchPolicy: 'no-cache',
      result({ data }: { data: UserSettingsToPickResult }) {
        if (data.UserSettingsToPick) {
          this.wantFor = data.UserSettingsToPick.notificationsFor;
          this.forLoader = false;
          this.load = true;
        }
      },
    },
    destinations: {
      query: destinationsForNotificationsQuery,
      result({ data }: { data: UserSettingsToPickResult }) {
        if (data.destinations) {
          this.wantFrom = data.destinations.notificationsFrom;
          this.fromLoader = false;
        }
      },
    },
  },
})
export default class Notifications extends Vue {
  forLoader = true;
  fromLoader = true;
  showSelectAllForIcon = false;
  showSelectAllFromIcon = false;
  wantFor: IVariant[] = [];
  wantFrom: IVariant[] = [];

  get selectedAllItemsFor() {
    return this.wantFor.filter((v: IVariant) => v.selected).length === this.wantFor.length;
  }

  get selectedAllItemsFrom() {
    return this.wantFrom.filter((v: IVariant) => v.selected).length === this.wantFrom.length;
  }

  @Watch('selectedAllItemsFor', { immediate: true })
  watchSelectedAllItemsFor(selectedAll: boolean) {
    this.showSelectAllForIcon = selectedAll;
  }

  @Watch('selectedAllItemsFrom', { immediate: true })
  watchSelectedAllItemsFrom(selectedAll: boolean) {
    this.showSelectAllFromIcon = selectedAll;
  }

  checkAll(data: string) {
    let arr: string[] = [];

    switch (data) {
      case 'for':
        this.showSelectAllForIcon = !this.showSelectAllForIcon;

        if (this.showSelectAllForIcon) {
          this.wantFor.forEach((value: IVariant) => {
            value.selected = true;
            arr.push(value.id);
          });
        } else {
          arr = [];
          this.wantFor.forEach((value: IVariant) => {
            value.selected = false;
          });
        }

        this.changeNotificationForSettings(arr);
        break;

      case 'from':
        this.showSelectAllFromIcon = !this.showSelectAllFromIcon;

        if (this.showSelectAllFromIcon) {
          this.wantFrom.forEach((value: IVariant) => {
            if (value) {
              value.selected = true;
              arr.push(value.id);
            }
          });
        } else {
          this.wantFrom.forEach((value: IVariant) => {
            if (value) {
              value.selected = false;
              arr = [];
            }
          });
        }

        this.changeNotificationFromDestinationSettings(arr);
        break;
    }
  }

  toggleVariantFor(variant: IVariant) {
    const notificationsFor: string[] = [];

    this.wantFor.forEach((value: IVariant) => {
      if (value.id == variant.id) {
        value.selected = variant.selected;
      }
      if (value.selected) {
        notificationsFor.push(value.id);
      }
    });
    this.changeNotificationForSettings(notificationsFor);
  }

  toggleVariantFrom(variant: IVariant) {
    const notificationsFrom: string[] = [];

    this.wantFrom.forEach((value: IVariant) => {
      if (value.id == variant.id) {
        value.selected = variant.selected;
      }
      if (value.selected) {
        notificationsFrom.push(value.id);
      }
    });
    this.changeNotificationFromDestinationSettings(notificationsFrom);
  }

  @Debounce(1000)
  changeNotificationForSettings(values: string[]) {
    this.$apollo
      .mutate({
        mutation: updateUserNotificationSettings,
        variables: {
          For: values,
        },
      })
      .then(({ data }) => {
        const selectedForIds = data.UsersNotificationsSettingsRequest.notificationsFor.map((v: IVariant) => v.id);

        this.wantFor.forEach((value: IVariant) => {
          value.selected = selectedForIds.includes(value.id);
        });
      });
  }

  @Debounce(1000)
  changeNotificationFromDestinationSettings(values: string[]) {
    this.$apollo
      .mutate({
        mutation: updateUserNotificationSettings,
        variables: {
          From: values,
        },
      })
      .then(({ data }) => {
        const selectedForIds = data.UsersNotificationsSettingsRequest.notificationsFrom.map((v: IVariant) => v.id);

        this.wantFrom.forEach((value: IVariant) => {
          value.selected = selectedForIds.includes(value.id);
        });
      });
  }
}
